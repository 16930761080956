theme-button {
    display: inline-block;
    border: 0;
    border-radius: 0;
    padding: 15px 30px;
    outline: 0;
    box-shadow: none;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Roboto-Medium';
    cursor: pointer;
    margin: 20px 0;

    @media screen and (max-width :768px) {
        padding: 10px 20px;
        font-size: 14px;
    }
}

.theme-button {
    background-color: #EC5698;
    @extend theme-button;
    color: #fff;




}