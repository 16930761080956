@import './components/index.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Roboto-Regular';
}

.hero {
    background-image: url('../../public/images/bg_image.png');
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 100%;
    background-attachment: fixed;
    z-index: 1;
    padding: 0 20px 30px 20px;
    text-align: center;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        min-height: 100%;
        background-image: linear-gradient(transparent, rgb(0 0 0 / 77%));
        z-index: -1;

    }

    .outer-box {
        max-width: 1300px;
        margin: 0 auto;
        width: 100%;
        background-color: rgba(225, 225, 225, 0.51);
        border-radius: 0px 0px 100vw 100vw;
        border: 1px solid $Primary-color;
        padding: 0px 0px 10px 0;

        @media screen and (max-width : 768px) {
            padding: 40px 30px !important;
            border-radius: 0px;
        }


        .logo {
            max-width: 50px;
            display: flex;
            margin: 0 auto;
            padding-top: 30px;

            img {
                width: 100%;
            }

            @media screen and (max-width :768px) {
                padding-top: 0;
            }

        }

        h2 {
            font-size: 38px;
            color: #363231;
            max-width: 750px;
            width: 100%;
            margin: 60px auto 30px;
            font-family: "Roboto-Medium";
            letter-spacing: 0;
            font-weight: 500;
            word-spacing: 0;

            @media screen and (max-width : 768px) {
                font-size: 27px;
                padding-top: 20px;
                margin: 0 auto 20px;

            }

        }

        .theme-button {
            background-color: $white-color;
            color: $violet-color;
        }

        .video-icon {
            text-decoration: none;
            max-width: 300px;
            display: flex;
            width: 100%;
            margin: 30px auto 10px;
            flex-direction: column;

            @media screen and (max-width : 768px) {
                margin: 10px auto;
            }

            img {

                max-width: 60px;
                display: flex;
                margin: 0 auto;
                margin-bottom: 14px;

                @media screen and (max-width :992px) {
                    max-width: 45px;
                    margin-bottom: 9px;
                }
            }

            p {
                font-size: 15px;
                color: $black-color;
                font-size: 500;
                padding-top: 5px;
            }
        }
    }

    .bottom-arrow {
        margin-top: 20px;

        img {
            animation: topBottom 6s ease 0s 2 normal;
            font-size: 40px;
            color: #fff;
            opacity: 0;
            visibility: hidden;
        }
    }

    .outer-text {
        margin-top: 50px;
        max-width: 600px;
        width: 100%;
        margin: 155px auto;

        @media screen and (max-width : 992px) {
            margin: 75px auto 100px;
        }

        h3 {
            font-size: 25px;
            color: $light-white-color;
            letter-spacing: 0px;
            font-family: 'Roboto-Medium';
            font-weight: 500;
        }

        .input-group-1 {
            width: 100%;
            max-width: 500px;

            margin: 70px auto 0 auto;
            justify-content: center;

            .theme-input-1 {
                border: 0;
                background-color: transparent;
                border-bottom: 1px solid $violet-color;
                width: 100%;
                font-size: 20px;
                padding: 10px;
                color: #fff;
                text-align: center;

                &:focus {
                    outline: 0;
                }

                &::placeholder {
                    color: rgba(255, 255, 255, 0.7);

                }
            }
        }
    }
}

@keyframes topBottom {

    0% {
        opacity: 0;
        visibility: hidden;
        transform: translateY(0px);
    }

    25% {
        opacity: 1;
        visibility: visible;
        transform: translateY(50px);
    }

    50% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
    }

    75% {
        opacity: 1;
        visibility: visible;
        transform: translateY(50px);
    }

    100% {
        opacity: 0;
        visibility: hidden;
        transform: translateY(0px);
    }

}

.video {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    iframe {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        pointer-events: none;
        user-select: none;


    }

    .header {
        width: 100%;
        max-width: 1100px;
        display: flex;
        padding: 0 20px;
        align-items: center;
        justify-content: center;
        margin-bottom: auto;
        margin-top: 30px;

        .logo-1 {
            width: 50px;

            img {
                width: 100%;

            }
        }
    }

}

.video__heading {
    font-size: 38px;
    font-family: 'Roboto-Medium';
    color: #fff;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 650px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 2;

    @media screen and (max-width : 768px) {
        font-size: 18px;
        padding: 0 20px;
    }

    &.text-1 {
        animation: fadIn 20s ease 3s infinite normal;
    }

    &.text-2 {
        animation: fadIn 20s ease 11s infinite normal;
    }
}

.video__bottom-text {
    text-align: center;
    margin-top: 0vh;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);

    h3 {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
        white-space: nowrap;

        @media screen and (max-width : 992px) {
            font-size: 15px;
        }
    }

    a {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        text-shadow: 0px 0px 3px rgb(0 0 0 / 50%);
        text-decoration: underline;

        @media screen and (max-width : 992px) {
            font-size: 13px;
        }
    }
}

@keyframes fadIn {

    0% {
        opacity: 0;
        visibility: hidden;
        transform: translateY(30px);
    }

    10% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
    }

    35% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
    }

    40% {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-30px);
    }

}